.itemcard
{
  border:10px;
  border-style: groove;
  border-image-source:  url("/src/resources/itemframe_plain.png");
  border-image-slice: 120 120 120 120 fill;
  border-image-repeat: repeat;
  border-image-width: 50px;
  padding: 1rem;
  text-align: left;
  font-family: 'PT Serif', serif;
}